import React from 'react';
import ReactDOM from 'react-dom';
import {App} from 'App';

import 'styles/global.sass';

import {loggerService} from './config/sentry';

loggerService.captureMessage('User connected to the app')

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
