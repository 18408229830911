export const debounce = (func: any, wait: number, immediate?: any) => {
  let timeout: any

  return function () {
    // @ts-ignore
    const context: any = this,
      // eslint-disable-next-line prefer-rest-params
      args = arguments
    const later = function () {
      timeout = null
      if (!immediate) {func.apply(context, args)}
    }
    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {func.apply(context, args)}
  }
}
