import * as Sentry from '@sentry/react'
import packageJson from '../../package.json'
import {SENTRY_DSN} from './env';

export interface LoggerOptions {
  apiKey: string
  version: string
}

class LoggerService {
  constructor({ apiKey, version }: LoggerOptions) {
    Sentry.init({
      dsn: apiKey,
      release: version,
    })
    console.log('Logger initialized')
  }

  captureMessage(message: string) {
    Sentry.captureMessage(message)
  }
}
export const loggerService = new LoggerService({
  apiKey: SENTRY_DSN as string,
  version: packageJson.version,
})
