import { useEffect, useState } from 'react'

import { debounce } from './debounce'


export type WindowSize = {
  width: number
  height: number
  ratio: number
  isLandscape: boolean
}

const getRatio = (isLandscape: boolean) => {
  return isLandscape ? window.innerWidth / window.innerHeight : window.innerHeight / window.innerWidth
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
    ratio: getRatio(false),
    isLandscape: false,
  })

  useEffect(() => {
    const handleResize = () => {
      const isLandscape = window.innerHeight <= window.innerWidth
      const ratio = getRatio(isLandscape)

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        ratio,
        isLandscape,
      })
    }

    handleResize()
    const debouncedResize = debounce(handleResize, 500)

    window.addEventListener('resize', debouncedResize)

    return () => window.removeEventListener('resize', debouncedResize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  return windowSize
}
