const ONE_MB_IN_BYTES = 1_048_576

enum ImageMime {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  WEBP = 'image/webp',
}

export type ToFileType = {
  type: 'create' | 'finish' | undefined
  grz?: string | null
  newStatus: string | undefined
  address: string
  // coords?: geoStorageType
};

type ProcessingOptions = {
  base64: string
};

export const getDecodedImage = async (imageFile: File) => {
  const result = await toBase64(imageFile)

  if (typeof result === 'string') {
    return result
  }

  return
}

const toBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const getImageFile = async (dataUrl: string): Promise<File> => {
  const blob = await processImage({ base64: dataUrl })

  return new File([blob], `${Date.now()}`, { type: 'image/png' })
}

export const processImage = async ({ base64 }: ProcessingOptions) => {
  const blob = await imgToCanvas(base64).then((canvas) =>
    compressImg(canvas, true)
  )

  return blob
}

const compressImg = async (canvas: HTMLCanvasElement, maxQuality?: boolean) => {
  const context = canvas.getContext('2d')
  let quality = 1
  //@ts-ignore
  let blob: Blob = await canvasToBlob(canvas, quality)

  if (!maxQuality) {
    while (blob.size > ONE_MB_IN_BYTES && quality > 0.1) {
      quality = quality - 0.05
      await createImageBitmap(blob).then((bit) =>
        context!.drawImage(bit, 0, 0)
      )
      //@ts-ignore
      blob = await canvasToBlob(canvas, quality)
    }
  }

  return blob
}

const canvasToBlob = async (canvas: HTMLCanvasElement, quality: number) => {
  return await new Promise((resolve) =>
    canvas.toBlob(resolve, ImageMime.JPEG, quality)
  )
}

const imgToCanvas = async (url: string) => {
  const img = document.createElement('img')

  img.src = url
  img.setAttribute('crossorigin', 'anonymous')
  await new Promise((resolve) => (img.onload = resolve))
  const canvas = document.createElement('canvas')

  canvas.width = img.width
  canvas.height = img.height
  // @ts-ignore
  canvas.getContext('2d').drawImage(img, 0, 0)

  return canvas
}


export function downloadFile(file: File) {
  // Create a temporary link element
  const link = document.createElement('a')

  // Set the href attribute to the data URL
  link.href = URL.createObjectURL(file)

  // Set the download attribute with the desired filename
  link.download = file.name

  // Append the link to the document
  document.body.appendChild(link)

  // Trigger a click on the link to start the download
  link.click()

  // Remove the link from the document
  document.body.removeChild(link)
}
