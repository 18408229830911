import React from 'react';
import styles from "./styles.module.sass";

interface PhotoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    isLastImage: boolean
    onDelete: (image: string) => void
}

export const Photo: React.FC<PhotoProps> = (props) => {
    let telegram = window.Telegram.WebApp
    const onDelete = () => {
        telegram.showConfirm("Вы уверены что хотите удалить фото?", (isConfirmed:boolean) => {
            if (isConfirmed){
                props.onDelete(props.src!)
            }
        })
    }
    return (
        <div className={styles.imageContainer}>
            <img
                {...props}
                alt={'photo item'}
                className={props.isLastImage ? styles.lastImage : styles.images}
            />
            <button className={styles.deleteBtn} onClick={onDelete}>
                <div></div>
            </button>
        </div>
    );
};

