/* eslint-disable no-console */
import axios from 'axios';


// TODO: Переделать метод на protected или v1. Токены хардкодить нельзя
export const token = 'e25d1e31814b65966798346f52713517';

type HostnameMap = {
    [key: string]: {
        API_URL: string
        GEO_PROTECTED_ID: string
        OPERATIONS_PROTECTED_ID: string
        EXTERNAL_API_URL: string
    }
}
const hostnameMap: HostnameMap = {
    'dev-twa.topdoer.ru': {
        API_URL: 'https://dev-api.ucar.pro',
        GEO_PROTECTED_ID: 'a774ab79-b04a-407d-bad7-36dc61b905c5',
        OPERATIONS_PROTECTED_ID: '1589dce4-d5c0-4093-8b18-0e1c9bcd9784',
        EXTERNAL_API_URL: 'https://dev-api.moika-moika.site',
    },
    'twa.topdoer.ru': {
        API_URL: 'https://api.ucar.pro',
        GEO_PROTECTED_ID: '6b113646-225f-4181-9a7d-e4cf3b2921a4',
        OPERATIONS_PROTECTED_ID: '4d5e24dd-724f-47e4-a201-754862c2b979',
        EXTERNAL_API_URL: 'https://api.moika-moika.site',
    },
    'demo-twa.topdoer.ru': {
        API_URL: 'https://api.topdoer.ru',
        GEO_PROTECTED_ID: '61e282f7-5386-4ee4-aea7-1612156c3c89',
        OPERATIONS_PROTECTED_ID: '841cdd3e-72b3-4d70-930b-d4eb0bacebb9',
        EXTERNAL_API_URL: 'https://admin.topdoer.ru',
    },
};

if (!hostnameMap[window.location.hostname]) {
    throw new Error('Unknown hostname');
}

const API_URL = hostnameMap[window.location.hostname].API_URL;
let GEO_PROTECTED_ID = hostnameMap[window.location.hostname].GEO_PROTECTED_ID;
let OPERATIONS_PROTECTED_ID = hostnameMap[window.location.hostname].OPERATIONS_PROTECTED_ID;
let EXTERNAL_API_URL = hostnameMap[window.location.hostname].EXTERNAL_API_URL;

interface AddressResponse {
    address: string
    time: string
}


export const apiV1 = axios.create({
    baseURL: API_URL,
    headers: {'Content-Type': 'application/json'},
});

export const getAddressTime = async (latitude: number, longitude: number): Promise<AddressResponse> => {
    const defaultResponse = {address: 'Address not found', time: new Date().toLocaleString('ru-RU', {timeZone: 'Europe/Moscow'})};
    try {
        const res = await apiV1.get<AddressResponse>(`/protected/${GEO_PROTECTED_ID}/dadata/geolocate/address`, {
            params: {
                latitude,
                longitude,
            },
            headers: {'Content-Type': 'application/json'},
        });

        if (res.status === 200) {
            return res.data;
        }
        return defaultResponse
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);

        return defaultResponse
    }
};

export const getOperations = async (id: string) => {
    try {
        const res =
            await apiV1.get(`/protected/${OPERATIONS_PROTECTED_ID}/operations/${id}`);

        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};


export type GetFileUrlPayload = {
    user_id: string
    name: string
    size: number
    mime_type: string
    file_type: string
}
export const getFileUrl = async (
    id: string,
    taskElementId: string,
    payload: GetFileUrlPayload,
) => {
    try {
        const res =
            await apiV1.post(`/v1/operations/${id}/elements/${taskElementId}/files`, payload,
                {headers: {'Authorization': 'Bearer ' + token}}
            );

        if (res.status === 200) {
            return res.data;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const uploadFile = async (url: string, body: File) => {
    try {
        const res =
            await axios.put(EXTERNAL_API_URL + url, body,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'content-type': body.type,
                    },
                });

        if (res.data) {
            console.log(res.data);
            // return res.data
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const approveDoerPhotos = async (doerUserId: string, taskElementId: string, URLs: string[],) => {

    try {
        const res =
            await apiV1.post(
                `/v1/doer_bot/doers/${doerUserId}/actions/approve_photos`,
                {
                    'task_element_id': taskElementId,
                    'photos': URLs.map((url) => ({'file_path': url})),
                });

        if (res.data) {
            console.log(res.data);
        }
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error.response.data);
    }
};