import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import Camera, {FACING_MODES} from 'react-html5-camera-photo';

import {uploadFile, getAddressTime, approveDoerPhotos, getFileUrl, getOperations, GetFileUrlPayload} from '../../api/api';
import {getImageFile} from '../../utils/filesUtils';
import {useWindowSize} from '../../utils/hooks/useWindowSize';

import styles from './styles.module.sass';
import {useLocation} from 'react-router-dom';
import {useQueryParams} from 'utils/hooks/useQueryParams';
import {Photo} from '../Photo/Photo';
import {Portal} from '../Portal/Portal';
import * as Sentry from '@sentry/react';
import {messages} from './webAppCam.model';
import {getInfrastructureLogger} from 'fork-ts-checker-webpack-plugin/lib/infrastructure-logger';

interface Location {
    latitude: number;
    longitude: number;
}

interface PopupButton {
    id?: string;
    type?: string;
    text?: string;
}

interface PopupParams {
    title?: string;
    message: string;
    buttons?: PopupButton[];
}

declare global {
    interface Window {
        Telegram: {
            WebApp: {
                onEvent: (event: string, callback: (data: any) => void) => void,
                close: () => void,
                expand: () => void,
                ready: () => () => boolean,
                MainButton: {
                    show: () => void,
                    enable: () => void,
                    disable: () => void,
                    setText: (text: string) => void
                    text: string
                    color: string
                    textColor: string
                    isVisible: boolean
                    isProgressVisible: boolean
                    isActive: boolean
                    onClick: (callback: () => void) => void
                    hide: () => void
                    setParams: (params: any) => void
                    showProgress: () => void
                    hideProgress: () => void

                },
                showAlert: (message: string, callback: () => void) => void
                showConfirm: (message: string, callback: (isConfirmed: boolean) => void) => void
                showPopup: (params: PopupParams, callback: (id: string) => void) => void
            }
        };
    }
}

var sendToServer = () => {
};
window.Telegram.WebApp.onEvent('mainButtonClicked', () => {
    sendToServer();
});

export const WebAppCam = () => {
    const tg = window.Telegram?.WebApp;
    const webcamRef = useRef<any>(null);
    const [facingMode, setFacingMode] = useState<any>(FACING_MODES.ENVIRONMENT);
    const [images, setImages] = useState<string[]>([]);
    const [isRetake, setIsRetake] = useState(false);
    const [operationData, setOperationData] = useState<any>(null);
    const [location, setLocation] = useState<Location | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
    const params = useQueryParams();
    const min = Number(params?.min_size || 1);
    const max = Number(params?.max_size || 10);
    const quetyLocation = useLocation();
    const queryParams = new URLSearchParams(quetyLocation.search);
    const operationId = queryParams.get('operation_id');
    const doerUserId = queryParams.get('doer_user_id');
    const taskElementId = queryParams.get('task_element_id');

    const size = useWindowSize();

    useEffect(() => {
        if (!operationData && operationId) {
            getOperations(operationId)
                .then((res) => setOperationData(res));
        }
    }, [operationData, operationId]);

    useEffect(() => {
        if (!navigator?.geolocation) {
            showGeoError();
            Sentry.captureMessage('Geolocation is not supported by your browser');

            return;
        }
        if (!location) {
            setIsLoading(true);

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {
                        latitude,
                        longitude,
                    } = position.coords;

                    setLocation({
                        latitude,
                        longitude,
                    });
                    setIsLoading(false);
                },
                (error) => {
                    showGeoError();
                    Sentry.captureMessage('Geolocation error: ' + error.message);
                },
                {
                    timeout: 10000
                }
            );
        }
    }, [location]);

    useEffect(() => {
        if (images.length < min || images.length > max) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg?.MainButton.setText(`Отправить ${images.length} фото`);
        }
    }, [images]);

    const handleClick = useCallback(() => {
        setFacingMode((prevState: string) => {
            if (prevState === FACING_MODES.USER) {
                return FACING_MODES.ENVIRONMENT;
            }

            return FACING_MODES.USER;
        });
    }, []);

    const capture = useCallback((imageSrc: string) => {
        addWatermark(imageSrc);

        setIsRetake(true);
    }, [webcamRef, operationData, location]);

    const retake = () => {
        setIsRetake(false);
        tg?.MainButton?.hide();
    };

    const draw = (image: HTMLImageElement, textLines: string[]) => {
        setIsLoading(true);
        const canvas = document.createElement('canvas');
        const lineHeight = 20;

        canvas.width = image.width;
        canvas.height = image.height;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(image, 0, 0);

            const startY = 20;
            const drawStroked = (text: string, x: number, y: number) => {
                ctx.font = "20px Arial"
                ctx.strokeStyle = 'black';
                ctx.lineWidth = 3;
                ctx.miterLimit = 2;
                ctx.strokeText(text, x, y);
                ctx.fillStyle = 'white';
                ctx.fillText(text, x, y);
            }

            textLines.forEach((line, index) => {
                const textWidth = ctx.measureText(line).width;
                const startX = canvas.width - textWidth - 20;
                drawStroked(line, startX, startY + index * lineHeight);
            });

            const watermarkedImageSrc = canvas.toDataURL('image/jpeg');

            setImages((prev) => [watermarkedImageSrc, ...prev]);
            setSelectedPhoto(watermarkedImageSrc);
            setIsLoading(false);
        }
    };

    const addWatermark = async (imageSrc: string) => {
        try {
            if (!operationData) {
                Sentry.captureMessage('operationData is not defined');
            }
            if (!location) {
                Sentry.captureMessage('location is not defined');
            }

            setIsLoading(true);

            const addressTimeResponse = await getAddressTime(location?.latitude || 0, location?.longitude || 0);

            const textLines = [
                '', // empty line needed for the first line
                `${operationData?.grz || ''}`,
                `${addressTimeResponse.time}`,
                `${operationData?.doer_name || 'doer_name'}`,
                `${operationData?.location?.name || 'location_name'}`,
                `${(location?.latitude || 'latitude') + ' ' + (location?.longitude || 'longitude')}`,
                addressTimeResponse.address.split(',').map((item) => item.trim()),
            ].flat();

            const image = new Image();

            image.src = imageSrc;

            image.onload = () => {
                draw(image, textLines);
            };
            setIsLoading(false);
        } catch (error) {
            Sentry.captureMessage('Error during image processing:' + error);
        }
    };
    sendToServer = async () => {
        if (images.length < min || images.length > max) {
            tg.showAlert('Превышено колличество фотографий', () => {
            });
            return;
        }
        tg.MainButton.showProgress();
        if (!operationId || !taskElementId || !doerUserId) {
            return;
        }

        try {
            const imgFiles = await Promise.all(images.map((image) => getImageFile(image)));
            const uploadData = await Promise.all(imgFiles.map(({name, size, type}) => {
                const payload: GetFileUrlPayload = {
                    name,
                    size,
                    user_id: doerUserId,
                    mime_type: type,
                    file_type: 'photo',
                };
                return getFileUrl(operationId, taskElementId, payload);
            }));

            const urls = await Promise.all(uploadData.map((item, index) =>
                uploadFile(item.url, imgFiles[index]).then(() => item.url)
            ));

            await approveDoerPhotos(doerUserId, taskElementId, urls);
        } catch (error) {
            Sentry.captureMessage('Error during image upload process:' + error);
        } finally {
            setImages([]);
            setIsRetake(false);
            tg.MainButton.hideProgress();
            tg.close();
        }
    };
    const handleDelete = (image: string) => {
        setImages(images.filter((el) => el !== image));

        if (images.length === 1) {
            setIsRetake(false);
            tg.MainButton.hide();
        }
        setSelectedPhoto(images[0] !== image ? images[0] : images[1]);
    };
    const cameraError = (error: Error) => {
        Sentry.captureMessage('Camera error: ' + error.message);
        tg?.showConfirm(messages.camera.error, (isConfirmed: boolean) => {
            if (isConfirmed) {
                window.location.reload();
            } else {
                tg.close();
            }
        });
    };

    const showGeoError = () => {
        tg?.showConfirm(messages.geo.error, (isConfirmed: boolean) => {
            if (isConfirmed) {
                window.location.reload();
            } else {
                tg.close();
            }
        });
    };

    if (!location?.latitude) {
        return <Portal showPortal={true}/>;
    }
    return (
        <div className={styles.container}>
            <Portal showPortal={isLoading}/>
            <div style={{
                // IMPORTANT: Do not remove this style
                display: isRetake ? 'none' : 'block',
                marginTop: '6rem',
                backgroundColor: 'var(--tg-theme-secondary-bg-color)'
            }}>
                <Camera
                    isSilentMode
                    onTakePhoto={capture}
                    idealFacingMode={facingMode}
                    onCameraError={cameraError}
                />
                <div className={styles.btnChangeCameraContainer}>
                    <button
                        onClick={handleClick}
                        className={styles.btnChangeCamera}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="47px" height="42px" version="1">
                            <g className={styles.svgG}>
                                <path
                                    d="M33.9,12.1H14.2L17.6,7c0.4-0.6,1-0.9,1.7-0.9h9.6c0.7,0,1.3,0.3,1.7,0.9L33.9,12.1z"/>
                                <path d="M14,11H8V9.2C8,8.5,8.5,8,9.2,8h3.6C13.5,8,14,8.5,14,9.2V11z"/>
                                <path
                                    d="M40,42H8c-2.2,0-4-1.8-4-4V14c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v24C44,40.2,42.2,42,40,42z"/>
                            </g>
                            <path fill="rgb(255,255,255)"
                                  d="M34,25c0-5.5-4.5-10-10-10c-2.4,0-4.6,0.8-6.3,2.2l1.2,1.6c1.4-1.1,3.1-1.8,5.1-1.8c4.4,0,8,3.6,8,8h-3.5 l4.5,5.6l4.5-5.6H34z"/>
                            <path fill="rgb(255,255,255)"
                                  d="M29.1,31.2C27.7,32.3,25.9,33,24,33c-4.4,0-8-3.6-8-8h3.5L15,19.4L10.5,25H14c0,5.5,4.5,10,10,10 c2.4,0,4.6-0.8,6.3-2.2L29.1,31.2z"/>
                        </svg>
                    </button>
                </div>
            </div>

            {
                isRetake && (
                    <div className={styles.container}>
                        <div className={styles.imageContainer}>
                            {
                                images.length > 0 && (
                                    <>
                                        <button
                                            className={styles.plusButton}
                                            disabled={images.length >= max}
                                            onClick={retake}
                                        >
                                            +
                                        </button>
                                        {
                                            images.map((img, index) => (
                                                <Photo
                                                    alt={`image ${index}`}
                                                    key={index}
                                                    src={img}
                                                    isLastImage={img === selectedPhoto}
                                                    onDelete={handleDelete}
                                                    onClick={() => setSelectedPhoto(img)}
                                                />
                                            ))
                                        }
                                    </>
                                )
                            }
                        </div>
                        {
                            !!images.length && selectedPhoto && (
                                <img
                                    src={selectedPhoto}
                                    alt="last image"
                                    className={styles.lastImageMain}
                                    style={{width: `${size.isLandscape ? '60%' : '100%'}`}}
                                />
                            )
                        }

                        <div className={styles.btnContainer}>
                            <p className={styles.warningMessage}>
                                {`Количество фото должно быть в диапазоне от ${min} до ${max}!`}
                            </p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};
