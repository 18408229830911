import React from 'react';
import {createPortal} from "react-dom";
import {MutatingDots} from "react-loader-spinner";

interface PortalProps {
    showPortal: boolean
}

export const Portal: React.FC<PortalProps> = ({showPortal}) => {
    return (
        <>
            {
                showPortal && createPortal(
                    <div style={{
                        position:"absolute",
                        top:"0",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "var(--tg-theme-bg-color)"
                    }}>
                        <MutatingDots
                            visible={true}
                            height={"100"}
                            width={"100"}
                            color="var(--tg-theme-button-color)"
                            secondaryColor="var(--tg-theme-button-color)"
                            radius={"12.5"}
                            ariaLabel={"mutating-dots-loading"}
                        />
                    </div>,
                    document.body
                )
            }
        </>
    );
};

