import React from 'react'
import { WebAppCam } from './components/WebAppCam/WebAppCam'
import {BrowserRouter, Route, Routes} from 'react-router-dom';


export const App: React.FC = () => {

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<WebAppCam/>} />
    </Routes>
</BrowserRouter>
}
